import { useTranslation } from 'react-i18next';

export const experiences = [
  {
    title: 'title_exp1',
    status: 'internship',
    duration: 'duration_exp1',
    description: 'desc_exp1',  
    colorStart: 'from-blue-400',
    colorEnd: 'to-blue-600',
    borderColor: 'border-blue-500',
    animationDelay: 0.3,
  },
  {
    title: 'title_exp5',
    status: 'freelance',
    duration: 'duration_exp5',
    description: 'desc_exp5',  
    colorStart: 'from-blue-400',
    colorEnd: 'to-blue-600',
    borderColor: 'border-blue-500',
    animationDelay: 0.1,
  },
  {
    title: 'title_exp2',
    status: 'fulltime',
    duration: 'duration_exp2',
    description: 'desc_exp2',  
    colorStart: 'from-blue-400',
    colorEnd: 'to-blue-600',
    borderColor: 'border-blue-500',
    animationDelay: 0.1,
  },
  {
    title: 'title_exp3',
    status: 'apprentice',
    duration: 'duration_exp3',
    description: 'desc_exp3',  
    colorStart: 'from-blue-400',
    colorEnd: 'to-blue-600',
    borderColor: 'border-blue-500',
    animationDelay: 0.2,
  },
  {
    title: 'title_exp4',
    status: 'contract',
    duration: 'duration_exp4',
    description: 'desc_exp4',  
    colorStart: 'from-blue-400',
    colorEnd: 'to-blue-600',
    borderColor: 'border-blue-500',
    animationDelay: 0.1,
  },
];
