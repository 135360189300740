import React from "react";
// import { ProjectData } from './data/dataProject';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Projects = () => {
  const { t } = useTranslation();

  const ProjectData = t("projects", { returnObjects: true });

  return (
    <div>
      <motion.h2
        className="text-3xl font-bold mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        {t("myProject")}
      </motion.h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {ProjectData.map((project, index) => (
          <motion.div
            key={index}
            className="bg-white shadow rounded-lg p-4 flex flex-col h-full"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1, duration: 0.5 }}
          >
            <h3 className="text-xl font-semibold mb-2">{t(project.title)}</h3>

            <motion.img
              src={project.image}
              alt={project.title}
              className="w-full h-48 object-contain rounded mb-2 mt-2 flex-shrink-0"
              loading="lazy"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            />

            <p className="mt-2 text-sm md:text-base flex-grow">
              {t(project.description)}
            </p>

            <div className="mt-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
              {Array.isArray(project.language) &&
                project.language.map((lang, index) => (
                  <button
                    key={index}
                    className="text-sm bg-gray-200 rounded-xl p-2 mb-2 text-gray-800"
                  >
                    {lang}
                  </button>
                ))}
            </div>

            <a
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium text-gray-800 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:ring-gray-200 rounded-xl px-8 py-4 mt-2 block shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-105"
            >
              {t(project.btnProject)}
            </a>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
