import React from 'react';
import { motion } from 'framer-motion';
import { experiences } from './data/dataExperience';
import { useTranslation } from 'react-i18next';

const Experience = () => {
  const { t } = useTranslation();

  const renderDescriptionWithLineBreaks = (description) => {
    return description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        <div style={{ paddingLeft: index >= 2 ? '8px' : '0' }}>
        {line}
        </div>
        {/* {index < description.split('\n').length - 1 && <br />} */}
        {index == 0 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className="max-w-7xl mx-auto px-0 md:px-6">
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.8,
          ease: 'easeOut',
        }}
        className="text-3xl font-bold text-center text-gray-800 mb-8"
      >
        {t('myExperience')}
      </motion.h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 relative">
        {experiences.map((experience, index) => (
          <motion.div
            key={index}
            className="relative"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.8,
              ease: 'easeOut',
              delay: 0.2 * index,
            }}
          >
            {/* Vertical line */}
            {/* {index < experiences.length - 1 && index !== Math.floor(experiences.length - 1) && index !== Math.floor(experiences.length - 2) && (
              <motion.div
                className="absolute left-1/2 top-full h-[100px] w-[5px] transform -translate-x-1/2 bg-blue-400 sm:block hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.8,
                  ease: 'easeOut',
                  delay: 1.1,
                }}
              />
            )} */}

            {/* Diagonal */}
            {index % 2 !== 0 && index !== experiences.length - 1 &&(
              <motion.div
                className="absolute bottom-0 right-full w-[140px] h-[4px] bg-blue-500 transform -rotate-45 origin-top-right sm:block hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.8,
                  ease: 'easeOut',
                  delay: 1.5,
                }}
                style={{
                  // transform: 'rotate(45deg)',
                  transformOrigin: 'top right',
                }}
              />
            )}

            {/* Horizontal line */}
            {/* {index === Math.floor(experiences.length - 2) && ( */}
            {index % 2 !== 1 && index !== experiences.length - 1 && (
              <motion.div
                className="absolute right-[-4rem] top-[50%] w-[65px] h-[5px] bg-blue-500 sm:block hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.8,
                  ease: 'easeOut',
                  delay: 1.5,
                }}
              />
            )}

            {/* Dot */}
            <div className="absolute top-[20px] left-[-20px] w-[40px] h-[40px] border-white border-8 bg-blue-500 rounded-full flex items-center justify-center">
              <span className="text-white text-lg font-bold">{index + 1}</span>
            </div>

            {/* Box */}
            <div
              className={`pl-4 md:pl-8 bg-white rounded-lg border-x-4 ${experience.borderColor} py-4 px-4 sm:px-8 shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-50 hover:shadow-2xl flex flex-col justify-between h-full`}
            >
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">{t(`experienceTitles.${experience.title}`)}</h3>
              <h3 className="text-md font-semibold text-gray-800 mb-2">{t(`experienceStatuses.${experience.status}`)}</h3>
              <p className="text-sm text-gray-600 mb-2">{t(`experienceDurations.${experience.duration}`)}</p>
              <p className="text-base text-gray-800 text-justify whitespace-normal break-words flex-grow">
                {/* {t(`experienceDescriptions.${experience.description}`)} */}
                {renderDescriptionWithLineBreaks(t(`experienceDescriptions.${experience.description}`))}
              </p>
            </div>

          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Experience;
